import { Table,Alert,Button,Form } from 'react-bootstrap';
import { createClaimsTable } from '../utils/claimUtils';

import '../styles/App.css';

import useFetchWithMsal from "../hooks/useFetchWithMsal";

const RolesAndGroups = (props)=> {
    const { error, execute } = useFetchWithMsal({
        scopes: ["api://fe0c7d4b-ac71-49b6-b0be-f3e6100f746f/api.scope"]
    });
    const {claims:{roles=[],groups=[]}={}} = props;
    const checkAuthorization = () => {
    
        execute('GET', "https://localhost:7255/msal/authorized").then((response) => {
            if (response) {
               
            }
        });
    };
    return (
              <>
                <Alert variant="success">
                  <Alert.Heading>Authentication was successful!</Alert.Heading>
                  <>
                  <hr />
                    <div> 
                        <div>   
                            <Form.Label>Role field present in Id token: </Form.Label>
                        </div>
                            <Form.Label>{roles.length > 0 ? <pre>{JSON.stringify(roles)}</pre> : "No role field is present"}</Form.Label>
                    </div>
                    <div>
                        <div>    
                            <Form.Label>Groups field present in Id token: </Form.Label>
                        </div>
                        <Form.Label>{groups.length > 0 ? <pre>{JSON.stringify(groups)}</pre> : "No role field is present"}</Form.Label>
                    </div>
                </>
               

                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button onClick={() => checkAuthorization()} variant="outline-success">
                      Api authorization
                    </Button>
                  </div>
                </Alert>
            </>

    );
  }

  


export const IdTokenData = (props) => {
    const tokenClaims = createClaimsTable(props.idTokenClaims);

    const tableRow = Object.keys(tokenClaims).map((key, index) => {
        return (
            <tr key={key}>
                {tokenClaims[key].map((claimItem) => (
                    <td key={claimItem}>{claimItem}</td>
                ))}
            </tr>
        );
    });

    return (
        <>
        <div>
            <RolesAndGroups claims={props.idTokenClaims} className="data-area-div"></RolesAndGroups>
        </div>
            <div className="data-area-div">
                <p>
                    See below the claims in your <strong> ID token </strong>. For more information, visit:{' '}
                    <span>
                        <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token">
                            docs.microsoft.com
                        </a>
                    </span>
                </p>

                <div className="data-area-div">
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Claim</th>
                                <th>Value</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
};