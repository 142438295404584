import { Badge, Button, Stack } from "react-bootstrap";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

export const RolesChecking = (props: any) => {
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  const { error, execute } = useFetchWithMsal({
    scopes: ["api://fe0c7d4b-ac71-49b6-b0be-f3e6100f746f/api.scope"]
});

const checkAuthorization = () => {


    execute('GET', "https://localhost:7255/msal/authorized").then((response) => {
        if (response) {
           
        }
    });
};

  return (
      <>
        <div>
        <Button variant="warning" type="button" onClick={() => checkAuthorization()}>
                            Cancel
                        </Button>
          <Stack direction="horizontal" gap={2}>
            <Badge bg="success">Success</Badge>
            <Badge bg="danger">Danger</Badge>
          </Stack>
        </div>
      </>
  );
}